import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Inhaltlich Verantwortlicher gemäß §10 Absatz 3 MDStV:`}</p>
    <p>{`Felix Bertram, Anschrift wie folgt:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ab4d0a0c242ff1221b858a100b553244/e17e5/flx_contact_med_hr.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACNElEQVQoz4WS2W7bMBRE/f+f1JegaJrUiet4l2Vrtygukqhd8pY7hei2QJ9KYHABghzMHNwJERGAv/okoqrpSBc19cOF+uFMbTeQUJqObkhBlFDTDv/8+aPxTACg78/QZY001zjFCfzAh+t5cP0Qnh/g4DiIThEyxSF4DNcPkPAUt9sdddujbnrc75+jFUZDUmlOtn0gy7KJJzFVRUZScArDyCiOY1KSm7tCKyqLlARnFIQR7fY27SybyqoxKSfX6x1RxDB9W2D6vsDH0oJ9cM18mS7NXK4PeH1bYb2xUegUeSahtUKeCVRlBikYpMofCYuyQhRG+PaywNtsY0x+vK/wsdzj+XWBp+c5ttYRq80ei5WFPBUPZRKZEkgVR5ZyRCeG6+2OidbaPHBdHyw+wfMCMBZD8gS27cB1PextF47jw/cDw1Hn0hgWWkGPxqmAkgnKqsWkKDSUSEwC++jB80Osd0f8XOyx2R1h2R5O0QmO45rqjusjSRik4HC9ADGLUVeZSSlVikk/nME5x3y5x+v7FquNjfnCwpenGV6mK3z9vsTsw8J2d8BsvjVoRq67EcN6bxodnQBBEGJsa9ZGqgwsjsFYAikeEiJBqgQETxBFkcHBEwbPCxH4oUERBqHBcHQ8RGGIsqwxGddaqRw65YbHyEJJbiopwSA4R1k1aLsew/mC6/WGrj//1gV100FKhYQxjG1NwrpuoVSGqqrQNC26fkDT9ib5MFzwv/NJQNedzXL/AjNaL7ZzOm/8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Felix Bertram Kontakt",
            "title": "Felix Bertram Kontakt",
            "src": "/static/ab4d0a0c242ff1221b858a100b553244/e17e5/flx_contact_med_hr.png",
            "srcSet": ["/static/ab4d0a0c242ff1221b858a100b553244/5a46d/flx_contact_med_hr.png 300w", "/static/ab4d0a0c242ff1221b858a100b553244/e17e5/flx_contact_med_hr.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      